import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useToast, Box, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [lastCheckedTime, setLastCheckedTime] = useState(new Date());
  const [consecutiveErrors, setConsecutiveErrors] = useState(0);
  const toast = useToast();
  const navigate = useNavigate();
  const serverUrl = process.env.REACT_APP_API_URL;
  const POLLING_INTERVAL = 10000; // Poll every 10 seconds
  const MAX_CONSECUTIVE_ERRORS = 3; // After this many errors, increase polling interval

  useEffect(() => {
    const token = Cookies.get('jwtToken');
    if (!token) return;

    let lastPollTime = Date.now();
    const MIN_POLL_INTERVAL = 5000;
    let currentPollingInterval = POLLING_INTERVAL;

    const fetchNotifications = async () => {
      const now = Date.now();
      if (now - lastPollTime < MIN_POLL_INTERVAL) return;
      lastPollTime = now;

      try {
        const response = await axios.get(`${serverUrl}/api/notifications`, {
          headers: { Authorization: `Bearer ${token}` },
          params: { after: lastCheckedTime.toISOString() }
        });

        // Reset consecutive errors on successful request
        if (consecutiveErrors > 0) {
          setConsecutiveErrors(0);
          currentPollingInterval = POLLING_INTERVAL; // Reset polling interval
        }

        const newNotifications = response.data;
        if (newNotifications.length > 0) {
          const mostRecentNotification = newNotifications[newNotifications.length - 1];
          setLastCheckedTime(new Date(mostRecentNotification.createdAt));
          
          newNotifications.forEach(notification => {
            toast({
              title: "Transcription Complete",
              description: (
                <Box 
                  onClick={() => notification.link && navigate(notification.link)}
                  cursor={notification.link ? 'pointer' : 'default'}
                  p={2}
                  borderRadius="md"
                >
                  <Text>{notification.message}</Text>
                  {notification.link && (
                    <Text 
                      color="blue.300" 
                      mt={1}
                      textDecoration="underline"
                      fontWeight="medium"
                      _hover={{ color: 'blue.600' }}
                    >
                      Click here to view your meeting
                    </Text>
                  )}
                </Box>
              ),
              status: notification.type || 'success',
              duration: 9000,
              isClosable: true,
              position: 'bottom',
              variant: 'solid',
            });
          });
        }
      } catch (error) {
        // Increment consecutive errors
        setConsecutiveErrors(prev => prev + 1);

        // If unauthorized, stop polling
        if (error.response?.status === 401) {
          clearInterval(pollInterval);
          return;
        }

        // If we have too many consecutive errors, increase polling interval
        if (consecutiveErrors >= MAX_CONSECUTIVE_ERRORS) {
          currentPollingInterval = POLLING_INTERVAL * 2; // Double the polling interval
          console.log('Increased polling interval due to consecutive errors');
        }

        // Log error but don't show to user
        console.warn('Notification fetch failed silently:', error.message);
      }
    };

    // Initial fetch
    fetchNotifications();

    // Set up polling with dynamic interval
    const pollInterval = setInterval(fetchNotifications, currentPollingInterval);

    return () => {
      clearInterval(pollInterval);
    };
  }, [serverUrl, toast, navigate, lastCheckedTime, consecutiveErrors]);

  return (
    <NotificationContext.Provider value={{ notifications }}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotifications = () => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('useNotifications must be used within a NotificationProvider');
  }
  return context;
}; 