import React, { useEffect, useState, useCallback } from 'react';
import { Box, Heading, Switch, HStack, VStack, Text, Spinner, Select, Button, SimpleGrid, Icon, Badge, GridItem, Divider, useToast, Tooltip, Input, InputGroup, InputRightElement, Collapse, List, ListItem, Card, CardBody, Link, Checkbox, Stack } from '@chakra-ui/react';
import GrayBox from '../../GrayBox';
import axios from 'axios';
import Cookies from 'js-cookie';
import { fetchEvents, EventBox, formatDate } from '../Meetings/Meetings';
import { motion, AnimatePresence } from 'framer-motion';
import { Skeleton, SkeletonText } from '@chakra-ui/react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FaPhone, FaEnvelope, FaCheckCircle, FaClock, FaSync, FaVideo, FaCalendar, FaColumns, FaExclamation, FaInfo, FaSearch, FaRobot, FaChevronUp, FaChevronDown } from 'react-icons/fa';

const formatMetadata = (data) => {
  const date = new Date(
    data?.metadata?.sentDateTime || data.receivedAt || data.startTime
  ).toLocaleString("en-US", {
    month: "numeric",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  });

  const clientNames = data?.clients
    .map((client) => `${client.firstName} ${client.lastName}`)
    .join(", ");

  return {
    title: data.title || data.metadata?.subject || data?.event?.subject || '',
    subtitle: date,
    clientNames,
    metadata: {
      ...data.metadata,
      date,
      clientNames,
    }
  };
};

const Home = ({ scrollRef }) => {
  const serverUrl = process.env.REACT_APP_API_URL;
  const [myFeed, setMyFeed] = useState([]);
  const [feed, setFeed] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resyncLoading, setResyncLoading] = useState(true);
  const [sortOption, setSortOption] = useState('dateAsc');
  const [filterOption, setFilterOption] = useState(() => {
    return localStorage.getItem('filterOption') || 'emails and calls';
  });
  const [showFavorites, setShowFavorites] = useState(false);
  const toast = useToast();
  const [events, setEvents] = useState([]);
  const [eventsLoading, setEventsLoading] = useState(false);
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Pagination states
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [viewMode, setViewMode] = useState('combined'); // 'combined' or 'split'

  const [dashboardStats, setDashboardStats] = useState({
    calls: { current: 0, change: 0 },
    emails: { current: 0, change: 0 },
    pendingActions: { count: 0, topClients: [] },
    timeSaved: { current: 0, change: 0 }
  });

  const [nextCursor, setNextCursor] = useState(null);
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const [displayedResults, setDisplayedResults] = useState([]);
  const [resultsPerPage] = useState(8);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    const fetchDashboardStats = async () => {
      try {
        const response = await axios.get(`${serverUrl}/api/dashboard-stats`, {
          headers: {
            'Authorization': `Bearer ${Cookies.get('jwtToken')}`
          }
        });
        setDashboardStats(response.data);
      } catch (error) {
        console.error('Error fetching dashboard stats:', error);
      }
    };

    fetchDashboardStats();
  }, [serverUrl]);

  const fetchFeed = useCallback(async (cursor = null, append = false) => {
    if (cursor) setIsFetchingMore(true);
    else setLoading(true);

    try {
      const response = await axios.get(`${serverUrl}/api/my-feed`, {
        params: {
          favoritesOnly: showFavorites,
          cursor,
          limit: itemsPerPage,
          filterOption
        },
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwtToken')}`
        }
      });

      const items = response.data?.items || [];
      const newNextCursor = response.data?.nextCursor || null;

      if (append) {
        setMyFeed(prevFeed => [...prevFeed, ...items]);
      } else {
        setMyFeed(items);
      }

      setNextCursor(newNextCursor);
      if (cursor) setIsFetchingMore(false);
      else setLoading(false);
    } catch (error) {
      console.error('Error fetching feed:', error);
      setLoading(false);
      setIsFetchingMore(false);
      setMyFeed([]);
      setNextCursor(null);
    }
  }, [serverUrl, showFavorites, itemsPerPage, filterOption]);

  useEffect(() => {
    fetchFeed();
  }, [fetchFeed]);

  const fetchJobs = async () => {
    try {
      const token = Cookies.get('jwtToken');
      const response = await axios.get(`${serverUrl}/api/jobs?type=Resync&status=active`, { headers: { Authorization: `Bearer ${token}` }, withCredentials: true });
      console.log('Jobs:', response.data);
      setResyncLoading(response.data.length > 0);
      if (response.data.length > 0) {
        setTimeout(fetchJobs, 10000); // Poll every 10 seconds until the length is 0
      } else {
        setResyncLoading(false);
      }
    } catch (error) {
      console.error('Error fetching jobs:', error);
    }
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  const handleShowArchived = (showArchived) => {
    console.log('Show Archived Correspondences:', showArchived);
    setLoading(true);
    axios.get(`${serverUrl}/api/my-feed`, {
      params: {
        showArchived,
        page: currentPage,
        limit: itemsPerPage
      },
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwtToken')}`
      }
    })
      .then(response => {
        console.log('Archived Correspondences:', response.data);
        // Ensure we have valid data
        const items = response.data?.items || [];
        const pagination = response.data?.pagination || {
          currentPage: 1,
          totalPages: 1,
          totalItems: 0,
          itemsPerPage: itemsPerPage
        };

        setMyFeed(items);
        setFeed(items);
        setTotalPages(pagination.totalPages);
        setTotalItems(pagination.totalItems);
        setCurrentPage(pagination.currentPage);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching archived items:', error);
        setLoading(false);
        // Set default states in case of error
        setMyFeed([]);
        setFeed([]);
        setTotalPages(1);
        setTotalItems(0);
        setCurrentPage(1);
      });
  };

  useEffect(() => {
    const handleScrollPosition = () => {
      const savedPosition = sessionStorage.getItem('scrollPosition');
      if (savedPosition && scrollRef.current) {
        scrollRef.current.scrollTo(0, parseInt(savedPosition, 10));
        sessionStorage.removeItem('scrollPosition'); 
      }
    };

    if (!loading) {
      handleScrollPosition();
    }
  }, [loading, scrollRef, filterOption]);

  const handleArchive = (itemId) => {
    console.log('Archive', itemId);
    // Update the state to remove the archived item
    let newFeed = myFeed.filter(item => item.id !== itemId);
    setMyFeed([...newFeed]);
  };

  // Function to split summary into sentences using new line characters
  const getListItems = (data) => {
    if (!data) return [];
    
    const summary = data?.correspondenceType === 'email' 
      ? data?.metadata?.summary 
      : data?.summary;

    if (!summary) return [];

    return summary
      .split('\n')
      .map(line => line.trim())
      .filter(line => line !== '');
  };

  const sortFeed = (feedToSort, option = sortOption) => {
    return feedToSort.sort((a, b) => {
      const dateA = new Date(a.metadata?.sentDateTime || a.receivedAt || a.startTime);
      const dateB = new Date(b.metadata?.sentDateTime || b.receivedAt || b.startTime);
      if (option === 'dateDesc') {
        return dateA - dateB;
      } else if (option === 'dateAsc') {
        return dateB - dateA;
      }
      // Add more sorting options if needed
      return 0;
    });
  };

  const handleSortChange = (e) => {
    const selectedOption = e.target.value;
    setSortOption(selectedOption);

    const sortedMyFeed = sortFeed([...myFeed], selectedOption);
    setMyFeed(sortedMyFeed);

    const sortedEvents = sortFeed([...events], selectedOption);
    setEvents(sortedEvents);
  };

  const handleFilterChange = (e) => {
    const selectedOption = e.target.value;
    setFilterOption(selectedOption);
    setMyFeed([]);
    setNextCursor(null);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    fetchFeed(newPage);
  };

  const refreshFeed = useCallback(() => {
    setLoading(true);
    console.log('Refreshing feed:');
    axios.get(`${serverUrl}/api/my-feed`, {
      params: {
        page: currentPage,
        limit: itemsPerPage,
        favoritesOnly: showFavorites
      },
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwtToken')}`
      }
    })
    .then(response => {
      console.log('Refreshed My Feed:', response.data);
      // Ensure we have valid data
      const items = response.data?.items || [];
      const pagination = response.data?.pagination || {
        currentPage: 1,
        totalPages: 1,
        totalItems: 0,
        itemsPerPage: itemsPerPage
      };

      setMyFeed(items);
      setFeed(items);
      setTotalPages(pagination.totalPages);
      setTotalItems(pagination.totalItems);
      setCurrentPage(pagination.currentPage);
      setLoading(false);
    })
    .catch(error => {
      console.error('Error refreshing feed:', error);
      setLoading(false);
      // Set default states in case of error
      setMyFeed([]);
      setFeed([]);
      setTotalPages(1);
      setTotalItems(0);
      setCurrentPage(1);
    });
  }, [serverUrl, currentPage, itemsPerPage, showFavorites]);

  // Updated filteredEvents to show events on the same calendar day
  const filteredEvents = events.filter(event => {
    const eventDate = new Date(event.startTime);
    const today = new Date();
    return (
      eventDate.getFullYear() === today.getFullYear() &&
      eventDate.getMonth() === today.getMonth() &&
      eventDate.getDate() === today.getDate()
    );
  });

  // Persist filterOption to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('filterOption', filterOption);

    if (filterOption === 'today') {
      fetchEvents(setEvents);
    }
  }, [filterOption]);

  // Get current items to display based on pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentFeedItems = myFeed; // No need to slice

  const loadMore = () => {
    if (nextCursor && !isFetchingMore) {
      fetchFeed(nextCursor, true);
    }
  };

  return (
    <Box bg="gray.50" minH="100vh" p={8} overflowY="auto">
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        {/* Header Section */}
        <Box mb={8}>
          <Heading color={'#00417D'} fontSize={'32px'}>
            My Dashboard
          </Heading>
          <Text color="gray.600" mt={2}>
            {new Date().toLocaleDateString('en-US', { 
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            })}
          </Text>
        </Box>

       

        {/* Statistics Section */}
        <SimpleGrid columns={{ base: 1, md: 4 }} spacing={6} mb={8}>
          <StatCard
            label="Calls This Month"
            value={dashboardStats.calls.current}
            icon={FaPhone}
            change={dashboardStats.calls.change}
          />
          <StatCard
            label="Emails Processed"
            value={dashboardStats.emails.current}
            icon={FaEnvelope}
            change={dashboardStats.emails.change}
          />
          <StatCard
            label="Time Saved"
            value={dashboardStats.timeSaved.hours}
            icon={FaClock}
            change={''}
          />
          <StatCard
            label="Pending Actions"
            value={dashboardStats.pendingActions.count}
            icon={FaExclamation}
            change={null}
          />
        </SimpleGrid>

        {/* Add AskDataDasher Section */}
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6} mb={8}>
          <GridItem colSpan={2}>
            <AskDataDasher />
          </GridItem>
        </SimpleGrid>

        {/* Status and Actions Section */}
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6} mb={8}>
          <PendingActionsCard stats={dashboardStats.pendingActions} />
          <QuickActions lastSyncTime={dashboardStats.lastResyncJobDate}/>
        </SimpleGrid>

        {/* Controls Section */}
        <HStack 
          spacing={8} 
          mb={8}
          p={4}
          bg="white"
          borderRadius="xl"
          boxShadow="sm"
          align="center"
        >
          <HStack flex={1} spacing={4}>
            <Select 
              value={sortOption} 
              onChange={handleSortChange}
              w="auto"
              size="sm"
              borderRadius="md"
              _focus={{ boxShadow: 'none', borderColor: 'blue.400' }}
            >
              <option value="dateAsc">Newest First</option>
              <option value="dateDesc">Oldest First</option>
            </Select>
            <Select 
              value={filterOption} 
              onChange={handleFilterChange}
              w="auto"
              size="sm"
              borderRadius="md"
              _focus={{ boxShadow: 'none', borderColor: 'blue.400' }}
            >
              <option value="today">Today's View</option>
              <option value="emails and calls">Emails and Calls</option>
              <option value="email">Emails</option>
              <option value="call">Calls</option>
            </Select>
          </HStack>

          <HStack spacing={4}>
            <Button
              size="sm"
              variant={viewMode === 'combined' ? 'solid' : 'ghost'}
              colorScheme="blue"
              onClick={() => setViewMode('combined')}
              leftIcon={<Icon as={FaColumns} />}
            >
              Combined
            </Button>
            <Button
              size="sm"
              variant={viewMode === 'split' ? 'solid' : 'ghost'}
              colorScheme="blue"
              onClick={() => setViewMode('split')}
              leftIcon={<Icon as={FaColumns} />}
            >
              Split View
            </Button>
            <Divider orientation="vertical" h="20px" />
            <HStack spacing={3}>
              <Text fontSize="sm" color="gray.600">Favorites Only</Text>
              <Switch
                isDisabled={loading}
                isChecked={showFavorites}
                onChange={(e) => setShowFavorites(e.target.checked)}
                size="sm"
                colorScheme="yellow"
              />
            </HStack>
            <Divider orientation="vertical" h="20px" />
            <HStack spacing={3}>
              <Text fontSize="sm" color="gray.600">Archived Items</Text>
              <Switch
                isDisabled={loading}
                onChange={(e) => handleShowArchived(e.target.checked)}
                size="sm"
                colorScheme="blue"
              />
            </HStack>
          </HStack>
        </HStack>
        {/* Content Section */}
        {loading ? (
          <LoadingState />
        ) : filterOption !== 'today' ? (
          <>
            <FeedContent 
              items={myFeed}
              viewMode={viewMode}
              onArchive={handleArchive}
              scrollRef={scrollRef}
              resyncLoading={resyncLoading}
              fetchJobs={fetchJobs}
              refreshFeed={refreshFeed}
            />

            {/* Load More Button */}
            {nextCursor && (
              <Box display="flex" justifyContent="center" mt={4}>
                <Button onClick={loadMore} isLoading={isFetchingMore}>
                  Load More
                </Button>
              </Box>
            )}
          </>
        ) : (
          <TodayView 
            events={filteredEvents}
            eventsLoading={eventsLoading}
            userTimeZone={userTimeZone}
          />
        )}

        {/* Pagination */}
        {totalPages > 1 && (
          <PaginationControls
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        )}
      </motion.div>
    </Box>
  );
};

// New Components
const LoadingState = () => (
  <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
    {[1, 2, 3, 4].map((index) => (
      <Box 
        key={index}
        bg="white"
        p={6}
        borderRadius="xl"
        boxShadow="sm"
      >
        <SkeletonText mt='4' noOfLines={3} spacing='4' />
        <HStack mt={4} spacing={4}>
          <Skeleton height="20px" width="100px" />
          <Skeleton height="20px" width="80px" />
        </HStack>
      </Box>
    ))}
  </SimpleGrid>
);

const FeedContent = ({ items = [], viewMode, ...props }) => {
  if (!items || items.length === 0) {
    return (
      <Box textAlign="center" py={8}>
        <Text color="gray.500">No items to display</Text>
      </Box>
    );
  }

  if (viewMode === 'split') {
    const emails = items.filter(item => item.correspondenceType === 'email');
    const calls = items.filter(item => item.correspondenceType === 'call');
    
    return (
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
        <VStack align="stretch" spacing={4}>
          <Text fontSize="lg" fontWeight="medium" color="gray.700">Emails</Text>
          <VStack align="stretch" spacing={4}>
            {emails.map((data, index) => (
              <FeedCard key={data.id} data={data} index={index} {...props} />
            ))}
            {emails.length === 0 && (
              <Text color="gray.500" textAlign="center">No emails to display</Text>
            )}
          </VStack>
        </VStack>
        <VStack align="stretch" spacing={4}>
          <Text fontSize="lg" fontWeight="medium" color="gray.700">Calls</Text>
          <VStack align="stretch" spacing={4}>
            {calls.map((data, index) => (
              <FeedCard key={data.id} data={data} index={index} {...props} />
            ))}
            {calls.length === 0 && (
              <Text color="gray.500" textAlign="center">No calls to display</Text>
            )}
          </VStack>
        </VStack>
      </SimpleGrid>
    );
  }

  // Combined view - single column
  return (
    <VStack spacing={4} align="stretch" w="100%" maxW="100%" mx="auto">
      {items.map((data, index) => (
        <FeedCard key={data.id} data={data} index={index} {...props} />
      ))}
    </VStack>
  );
};

const FeedCard = ({ data, index, ...props }) => {
  const getListItems = (data) => {
    if (!data) return [];
    
    const summary = data?.correspondenceType === 'email' 
      ? data?.metadata?.summary 
      : data?.summary;

    if (!summary) return [];

    return summary
      .split('\n')
      .map(line => line.trim())
      .filter(line => line !== '');
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3, delay: index * 0.1 }}
    >
      <Box 
        bg="white"
        borderRadius="xl"
        boxShadow="sm"
        overflow="hidden"
        transition="all 0.2s"
        _hover={{ boxShadow: 'md' }}
      >
        <GrayBox
          {...formatMetadata(data)}
          listItems={getListItems(data)}
          rows={data?.ActionItem}
          transcriptId={data?.id}
          client={data?.clients}
          type={data?.correspondenceType}
          id={data?.id}
          onArchive={() => props.onArchive(data.id)}
          scrollRef={props.scrollRef}
          errorItems={data?.ActionItem.filter(item => item.aiCompletionStatus === 'ERROR')}
          correspondence={data}
          nonClientSpeakers={data?.nonClientSpeakers}
          resyncLoading={props.resyncLoading}
          fetchJobs={props.fetchJobs}
          refreshFeed={props.refreshFeed}
        />
      </Box>
    </motion.div>
  );
};

const TodayView = ({ events, eventsLoading, userTimeZone }) => (
  <Box>
    <Text color="gray.600" fontSize="md" mb={4}>
      Events from your Outlook Calendar for today will be displayed here.
    </Text>
    
    {eventsLoading ? (
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
        {[1, 2].map((index) => (
          <Box key={index} p={4} borderWidth="1px" borderRadius="lg">
            <SkeletonText noOfLines={2} spacing="4" />
            <HStack mt={4} spacing={4}>
              <Skeleton height="20px" width="120px" />
              <Skeleton height="20px" width="100px" />
            </HStack>
          </Box>
        ))}
      </SimpleGrid>
    ) : events.length > 0 ? (
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
        {events.map((event) => (
          <EventBox
            key={event.id}
            eventId={event.id}
            subject={event.subject}
            startTime={formatDate(event.startTime, userTimeZone)}
            endTime={formatDate(event.endTime, userTimeZone)}
            webConfLink={event.webConfLink}
            location={event.location}
            audioBotJoinStatus={event.audioBotJoinStatus ?? true}
            botId={event.botId ?? null}
          />
        ))}
      </SimpleGrid>
    ) : (
      <Box textAlign="center" py={8}>
        <Text color="gray.500">No events scheduled for today.</Text>
      </Box>
    )}
  </Box>
);

const PaginationControls = ({ currentPage, totalPages, onPageChange }) => (
  <HStack justify="center" mt={6} spacing={4}>
    <Button
      onClick={() => onPageChange(currentPage - 1)}
      isDisabled={currentPage === 1}
      size="sm"
      variant="outline"
    >
      Previous
    </Button>
    <Text color="gray.600">
      Page {currentPage} of {totalPages}
    </Text>
    <Button
      onClick={() => onPageChange(currentPage + 1)}
      isDisabled={currentPage === totalPages}
      size="sm"
      variant="outline"
    >
      Next
    </Button>
  </HStack>
);

const StatCard = ({ label, value, icon, change }) => (
  <Box
    bg="white"
    p={6}
    borderRadius="xl"
    boxShadow="sm"
    transition="all 0.2s"
    _hover={{ boxShadow: 'md' }}
  >
    <HStack justify="space-between" mb={2}>
      <Tooltip
        label={label === "Time Saved" ? "Estimated time saved based on typical user behavior" : ""}
        hasArrow
        placement="top"
        openDelay={300}
        isDisabled={label !== "Time Saved"}
      >
        <HStack>
          <Icon as={icon} boxSize={5} color="blue.500" />
          {label === "Time Saved" && (
            <Icon as={FaInfo} boxSize={3} color="gray.400" />
          )}
        </HStack>
      </Tooltip>
      {change && (
        <Tooltip
          label="Compared to last month"
          hasArrow
          placement="top"
          openDelay={300}
        >
          <Text fontSize="sm" color={change > 0 ? "green.500" : "red.500"}>
            {change > 0 ? "+" : ""}{change}%
          </Text>
        </Tooltip>
      )}
    </HStack>
    <Text fontSize="2xl" fontWeight="bold" mb={1}>
      {value}
    </Text>
    <Text fontSize="sm" color="gray.600">
      {label}
    </Text>
  </Box>
);

const PendingActionsCard = ({ stats }) => (
  <Box
    bg="white"
    p={6}
    borderRadius="xl"
    boxShadow="sm"
    w="100%"
  >
    <HStack justify="space-between" mb={4}>
      <Text fontSize="lg" fontWeight="medium">Top Clients with Pending Actions</Text>
      <Button 
        as={RouterLink}
        to="/clients"
        size="sm" 
        colorScheme="blue" 
        variant="ghost"
      >
        View All
      </Button>
    </HStack>
    <VStack align="stretch" spacing={3}>
      {stats.topClients.map((client) => (
        <HStack 
          key={client.id} 
          justify="space-between"
          p={3}
          bg="gray.50"
          borderRadius="md"
          _hover={{
            bg: 'gray.100',
            cursor: 'pointer'
          }}
          as={RouterLink}
          to={`/clients/${client.id}`}
          transition="all 0.2s"
        >
          <Text color="blue.600" fontWeight="medium">
            {client.name}
          </Text>
          <Badge colorScheme="red">{client.count} pending</Badge>
        </HStack>
      ))}
      {stats.topClients.length === 0 && (
        <Text color="gray.500" textAlign="center">No pending actions</Text>
      )}
    </VStack>
  </Box>
);

PendingActionsCard.propTypes = {
  stats: PropTypes.shape({
    count: PropTypes.number,
    topClients: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      count: PropTypes.number
    }))
  }).isRequired
};

const QuickActions = ({ lastSyncTime }) => {
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [eventsLoading, setEventsLoading] = useState(true);
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [resyncLoading, setResyncLoading] = useState(false);
  const serverUrl = process.env.REACT_APP_API_URL;
  const toast = useToast();
  const handleResync = async () => {
    setResyncLoading(true);
    console.log('handleResync - Initiating resync process');
    try {
      console.warn('handleResync - Resyncing all clients for the organization');
      const response = await axios.post(`${serverUrl}/api/clients/resync-all`, {}, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get('jwtToken')}`
        }
      });

      if (response.data.message) {
        toast({
          title: "Resync Initiated",
          description: "All client data resync has been initiated. This may take a few moments.",
          status: "info",
          duration: 5000,
          isClosable: true,
        });
        setResyncLoading(true);
        // wait 10 seconds before fetching jobs
        setTimeout(fetchJobs, 10000);
      } else {
        throw new Error(response.data.message || "Failed to initiate resync");
      }
    } catch (error) {
      console.error("Error initiating resync:", error);
      toast({
        title: "Resync Failed",
        description: `Failed to initiate resync: ${error.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      console.log('Clients Component: handleResync - Resync process completed');
    }
  };

  const fetchJobs = async (bypassToast = false) => {
    try {
      const token = Cookies.get('jwtToken');
      const response = await axios.get(`${serverUrl}/api/jobs?type=Resync&status=active`, { headers: { Authorization: `Bearer ${token}` }, withCredentials: true });
      console.log('Jobs:', response.data);
      setResyncLoading(response.data.length > 0);
      if (response.data.length > 0) {
        setTimeout(fetchJobs, 10000); // Poll every 10 seconds until the length is 0
      } else {
        setResyncLoading(false);
        if (!bypassToast) {
          toast({
            title: "Resync Completed",
            description: "All client data resync has been completed. Refresh the page to see the latest data.",
            status: "info",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    } catch (error) {
      console.error('Error fetching jobs:', error);
    }
  };

  const formatDistanceToNow = (date) => {
    const now = new Date();
    const distanceInMilliseconds = now - new Date(date);
    const distanceInMinutes = Math.floor(distanceInMilliseconds / 60000);

    if (!date) {
      return 'Never';
    }

    if (distanceInMinutes < 1) {
      return 'just now';
    } else if (distanceInMinutes < 60) {
      return `${distanceInMinutes} minute${distanceInMinutes > 1 ? 's' : ''} ago`;
    } else if (distanceInMinutes < 1440) {
      const hours = Math.floor(distanceInMinutes / 60);
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else {
      const days = Math.floor(distanceInMinutes / 1440);
      return `${days} day${days > 1 ? 's' : ''} ago`;
    }
  };

  useEffect(() => {
    setEventsLoading(true);
    setResyncLoading(true);
    fetchEvents((events) => {
      const now = new Date();
      const upcoming = events
        .filter(event => new Date(event.startTime) >= now)
        .slice(0, 2); // Show only next 2 upcoming meetings
      setUpcomingEvents(upcoming);
      setEventsLoading(false);
    });
    fetchJobs(true);
  }, []);

  return (
    <Box
      bg="white"
      p={6}
      borderRadius="xl"
      boxShadow="sm"
      w="100%"
    >
      <Text fontSize="lg" fontWeight="medium" mb={4}>Quick Actions</Text>
      <VStack spacing={4} align="stretch">
        <Button
          height="16"
          colorScheme="blue"
          leftIcon={<Icon as={FaVideo} boxSize={5} />}
          fontSize="md"
          _hover={{
            transform: 'translateY(-1px)',
            boxShadow: 'md'
          }}
          transition="all 0.2s"
          as={RouterLink}
          to="/live-meeting"
        >
          Record Meeting
        </Button>
        
        <HStack 
          p={4} 
          bg="gray.50"
          borderRadius="lg"
          justify="space-between"
        >
          <HStack>
            <Icon as={FaSync} color={lastSyncTime ? "blue.500" : "gray.400"} />
            <VStack align="start" spacing={0}>
              <Text fontSize="sm" color="gray.600">Last CRM Sync</Text>
              {lastSyncTime ? (
                <Text fontWeight="medium">{formatDistanceToNow(new Date(lastSyncTime), { addSuffix: true })}</Text>
              ) : (
                <Text fontWeight="medium" color="gray.500">Not Synced</Text>
              )}
            </VStack>
          </HStack>
          <Button
            size="sm"
            leftIcon={<FaSync />}
            colorScheme="blue"
            variant="ghost"
            onClick={handleResync}
            isLoading={resyncLoading}
            isDisabled={!lastSyncTime}
            _disabled={{
              opacity: 0.6,
              cursor: 'not-allowed'
            }}
          >
            Sync Now
          </Button>
        </HStack>

        <Box>
          <HStack justify="space-between" mb={2}>
            <Text fontSize="sm" fontWeight="medium" color="gray.700">Upcoming Meetings</Text>
            <Button
              as={RouterLink}
              to="/meetings"
              size="xs"
              variant="ghost"
              colorScheme="blue"
            >
              View All
            </Button>
          </HStack>
          
          <VStack spacing={3} align="stretch">
            {eventsLoading ? (
              <Skeleton height="100px" borderRadius="lg" />
            ) : upcomingEvents.length > 0 ? (
              upcomingEvents.map(event => (
                <EventBox
                  key={event.id}
                  eventId={event.id}
                  subject={event.subject}
                  startTime={formatDate(event.startTime, userTimeZone)}
                  endTime={formatDate(event.endTime, userTimeZone)}
                  webConfLink={event.webConfLink}
                  location={event.location}
                  audioBotJoinStatus={event.audioBotJoinStatus ?? true}
                  botId={event.botId ?? null}
                />
              ))
            ) : (
              <Text color="gray.500" fontSize="sm" textAlign="center" py={4}>
                No upcoming meetings
              </Text>
            )}
          </VStack>
        </Box>
      </VStack>
    </Box>
  );
};

const AskDataDasher = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const serverUrl = process.env.REACT_APP_API_URL;

  // Store the user's question in one state...
  const [question, setQuestion] = useState('');
  // ...and store the final search keyword to highlight in another.
  const [searchKeyword, setSearchKeyword] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [answer, setAnswer] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [exactMatch, setExactMatch] = useState(false);

  // New pagination states
  const [displayedResults, setDisplayedResults] = useState([]);
  const [resultsPerPage] = useState(8);
  const [hasMore, setHasMore] = useState(false);

  // Suggestions for AI-based queries
  const aiSuggestions = [
    "Which clients have recently asked about ESG investment options?",
    "Which clients are sending kids to college?",
    "Does Clara like to ski?"
  ];

  // Suggestions for exact-match mode
  const exactMatchSuggestions = [
    "retirement",
    "urgent",
    "follow-up"
  ];

  const handleAsk = async () => {
    if (!question.trim()) return;
    setIsLoading(true);

    try {
      let response;
      if (exactMatch) {
        response = await axios.get(`${serverUrl}/api/exact-match-search`, {
          params: { query: question.trim() },
          headers: {
            Authorization: `Bearer ${Cookies.get('jwtToken')}`
          }
        });
      } else {
        response = await axios.post(`${serverUrl}/api/ask-organization`,
          { question: question.trim() },
          {
            headers: {
              Authorization: `Bearer ${Cookies.get('jwtToken')}`
            }
          }
        );
      }
      
      setSearchKeyword(question);
      setAnswer(response.data);
      
      // Handle pagination for array results
      if (Array.isArray(response.data)) {
        setDisplayedResults(response.data.slice(0, resultsPerPage));
        setHasMore(response.data.length > resultsPerPage);
      }
      
      setQuestion('');
    } catch (error) {
      console.error('Error asking question:', error);
      toast({
        title: 'Error',
        description: 'Failed to get answer. Please try again.',
        status: 'error',
        duration: 3000
      });
    } finally {
      setIsLoading(false);
    }
  };

  const loadMore = () => {
    if (Array.isArray(answer)) {
      const currentLength = displayedResults.length;
      const newResults = answer.slice(currentLength, currentLength + resultsPerPage);
      setDisplayedResults([...displayedResults, ...newResults]);
      setHasMore(currentLength + resultsPerPage < answer.length);
    }
  };

  // Highlight the matched query text
  function highlightText(text, query) {
    if (!query) return text;
    const regex = new RegExp(`(${query})`, 'gi');
    return text.replace(regex, '<span style="background-color: yellow;">$1</span>');
  }

  return (
    <Box
      bg="white"
      p={6}
      borderRadius="xl"
      boxShadow="sm"
      transition="all 0.2s"
      _hover={{ boxShadow: 'md' }}
    >
      <VStack spacing={4} align="stretch">
        {/* Title Bar */}
        <HStack>
          <Icon as={FaRobot} color="blue.500" boxSize={5} />
          <Text fontSize="lg" fontWeight="medium">Ask DataDasher</Text>
        </HStack>

        {/* Search Controls */}
        <Stack direction="column" spacing={2}>
          <InputGroup size="md">
            <Box flex="1" mr="5rem">
              <Input
                width="100%"
                placeholder="Ask anything about your organization..."
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && handleAsk()}
              />
            </Box>
            <InputRightElement width="5rem">
              <Button
                h="2.5rem"
                size="sm"
                onClick={handleAsk}
                isLoading={isLoading}
                colorScheme="blue"
              >
                Ask
              </Button>
            </InputRightElement>
          </InputGroup>

          <Checkbox
            isChecked={exactMatch}
            onChange={(e) => setExactMatch(e.target.checked)}
          >
            <Tooltip
              label="When enabled, DataDasher will search for the exact word (partial word matches included), across your calls and emails."
              hasArrow
              openDelay={300}
              placement="top"
            >
              <Box as="span" cursor="help">
                Exact Match
              </Box>
            </Tooltip>
          </Checkbox>
        </Stack>

        {/* Add Results Count */}
        {Array.isArray(answer) && answer.length > 0 && (
          <Box>
            <Text color="gray.600" fontSize="sm">
              Found {answer.length} matching {answer.length === 1 ? 'result' : 'results'}
            </Text>
          </Box>
        )}

        {/* Single Object Answer (Usually from the AI) */}
        {answer && !Array.isArray(answer) && (
          <Card variant="outline">
            <CardBody>
              <Box
                dangerouslySetInnerHTML={{ __html: answer.response }}
                sx={{
                  'h1': { fontSize: '2xl', fontWeight: 'bold', mb: 4 },
                  'h2': { fontSize: 'xl', fontWeight: 'bold', mb: 3 },
                  'h3': { fontSize: 'lg', fontWeight: 'bold', mb: 2 },
                  'p': { mb: 4 },
                  'ul, ol': { mb: 4, pl: 6 },
                  'li': { mb: 2 },
                  'table': {
                    width: '100%',
                    mb: 4,
                    borderCollapse: 'collapse',
                    'th, td': {
                      border: '1px solid',
                      borderColor: 'gray.200',
                      p: 2
                    },
                    'tr:nth-of-type(even)': {
                      bg: 'gray.50'
                    }
                  }
                }}
              />
              {answer.citations && answer.citations.length > 0 && (
                <Box mt={4} pt={4} borderTop="1px" borderColor="gray.200">
                  <Text fontSize="sm" fontWeight="medium" mb={2}>Sources:</Text>
                  <VStack align="stretch" spacing={2}>
                    {answer.citations.map((citation, index) => (
                      <HStack key={index} spacing={2}>
                        <Text fontSize="sm" color="gray.500">[{index + 1}]</Text>
                        {citation.link ? (
                          <Link
                            as={RouterLink}
                            to={citation.link}
                            fontSize="sm"
                            color="blue.500"
                            _hover={{ textDecoration: 'underline' }}
                          >
                            {citation.description}
                          </Link>
                        ) : (
                          <Text fontSize="sm" color="gray.600">
                            {citation.description}
                          </Text>
                        )}
                        <Badge
                          size="sm"
                          colorScheme={
                            citation.type === 'email'
                              ? 'blue'
                              : citation.type === 'call'
                              ? 'green'
                              : citation.type === 'client'
                              ? 'purple'
                              : 'gray'
                          }
                        >
                          {citation.type}
                        </Badge>
                      </HStack>
                    ))}
                  </VStack>
                </Box>
              )}
            </CardBody>
          </Card>
        )}

        {/* Array of Matches (Usually from Exact Match Search) */}
        {Array.isArray(answer) && displayedResults.length > 0 ? (
          <VStack spacing={4}>
            {displayedResults.map((result, index) => (
              <Card
                key={index}
                variant="outline"
                p={4}
                borderRadius="md"
                boxShadow="sm"
                width="100%"
                cursor="pointer"
                onClick={() => {
                  navigate(`/transcripts/${result.textBodyId}`);
                }}
              >
                <CardBody>
                  <HStack spacing={2} mb={0}>
                    <Link
                      as={RouterLink}
                      to={`/clients/${result.clientId}`}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Text fontWeight="bold" fontSize="lg" color="blue.500" _hover={{ textDecoration: 'underline' }}>
                        {result.clientName}
                      </Text>
                    </Link>
                    <Text fontSize="sm" color="gray.500">
                      {result.typeOfInformation}
                    </Text>
                  </HStack>
                  <Text fontSize="sm" color="gray.500" mt={1}>
                    {result.callTitle || 'Untitled'}
                  </Text>
                  <Text
                    fontSize="md"
                    color="gray.700"
                    mt={1}
                    dangerouslySetInnerHTML={{ __html: highlightText(result.text, searchKeyword) }}
                  />
                </CardBody>
              </Card>
            ))}
            
            {hasMore && (
              <Button
                onClick={loadMore}
                size="sm"
                variant="ghost"
                width="100%"
                mt={2}
                colorScheme="blue"
              >
                Load More Results
              </Button>
            )}
          </VStack>
        ) : null}

        {/* Suggestion Toggle */}
        <Box>
          <Button
            variant="link"
            size="sm"
            onClick={() => setIsExpanded(!isExpanded)}
            rightIcon={<Icon as={isExpanded ? FaChevronUp : FaChevronDown} />}
            color="gray.500"
          >
            {isExpanded ? 'Hide suggestions' : 'Show suggestions'}
          </Button>
          
          <Collapse in={isExpanded}>
            <List spacing={2} mt={2}>
              {(exactMatch ? exactMatchSuggestions : aiSuggestions).map((q, idx) => (
                <ListItem
                  key={idx}
                  cursor="pointer"
                  _hover={{ color: 'blue.500' }}
                  onClick={() => setQuestion(q)}
                  fontSize="sm"
                  color="gray.600"
                >
                  <Icon as={FaSearch} boxSize={3} mr={2} />
                  {q}
                </ListItem>
              ))}
            </List>
          </Collapse>
        </Box>
      </VStack>
    </Box>
  );
};

export default Home;