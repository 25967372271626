import React, { useState, useRef, useEffect, memo } from 'react';
import {
  Box,
  VStack,
  Input,
  IconButton,
  Text,
  useToast,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerContent,
  DrawerCloseButton,
  Flex,
  Spinner,
  Divider
} from '@chakra-ui/react';
import { ChatIcon, ArrowForwardIcon, ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import axios from 'axios';
import Cookies from 'js-cookie';

// Memoize the message component to prevent unnecessary re-renders
const ChatMessage = memo(({ message }) => {
  // Function to format the message content
  const formatContent = (content) => {
    return content
      // Remove markdown-style formatting
      .replace(/\*\*/g, '')
      .replace(/\*/g, '')
      .replace(/`/g, '')
      // Add line breaks before lists or numbered items
      .replace(/(?<=\n|^)(\d+\.|-)(?=\s)/g, '\n$1')
      // Add line breaks before new sentences that start with capital letters (but don't add extra periods)
      .replace(/([.!?])\s+([A-Z])/g, '$1\n\n$2')
      // Ensure proper spacing after commas
      .replace(/,(\S)/g, ', $1')
      // Remove extra spaces
      .replace(/\s{2,}/g, ' ')
      // Add spacing after colons
      .replace(/:(\S)/g, ': $1')
      // Ensure proper indentation for lists
      .replace(/(?<=\n)(\d+\.|-)(\s*)/g, '  $1 ')
      // Clean up any triple+ newlines
      .replace(/\n{3,}/g, '\n\n')
      .trim();
  };

  return (
    <Box
      bg={message.type === 'user' ? 'blue.50' : 'gray.50'}
      p={4}
      borderRadius="md"
      mb={3}
      maxW="90%"
      ml={message.type === 'user' ? 'auto' : '0'}
    >
      <Text fontWeight="bold" mb={2}>
        {message.type === 'user' ? 'You' : 'DataDasher'}
      </Text>
      <Text 
        whiteSpace="pre-wrap"
        lineHeight="1.8"
        fontSize="md"
        letterSpacing="0.2px"
        sx={{
          'p + p': { marginTop: '1em' },
          'ul, ol': { marginLeft: '1.5em', marginY: '0.5em' },
          'li': { marginY: '0.25em' }
        }}
      >
        {formatContent(message.content)}
      </Text>
    </Box>
  );
});

const TranscriptChatSidebar = ({ isOpen, onClose, transcriptContent, callId }) => {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const toast = useToast();
  const serverUrl = process.env.REACT_APP_API_URL;

  // Add state for streaming message
  const [streamingMessage, setStreamingMessage] = useState('');
  
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (messages.length > 0) {
      scrollToBottom();
    }
  }, [messages]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputValue.trim()) return;

    const newMessage = {
      type: 'user',
      content: inputValue,
      timestamp: new Date().toISOString()
    };

    setMessages(prev => [...prev, newMessage]);
    setInputValue('');
    setIsLoading(true);
    setStreamingMessage('');

    try {
      // Get the last 6 messages for context (or adjust as needed)
      const recentMessages = messages.slice(-6);
      
      const response = await fetch(`${serverUrl}/qa/transcript/${callId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get('jwtToken')}`,
        },
        body: JSON.stringify({
          question: inputValue,
          transcriptContent,
          chatHistory: recentMessages // Send recent chat history
        })
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let fullResponse = '';

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value);
        const lines = chunk.split('\n');

        for (const line of lines) {
          if (line.startsWith('data: ')) {
            const data = line.slice(5).trim();
            
            if (data === '[DONE]') {
              continue;
            }

            try {
              const parsed = JSON.parse(data);
              if (parsed.content) {
                fullResponse += parsed.content;
                setStreamingMessage(fullResponse);
              }
            } catch (e) {
              if (data !== '[DONE]') {
                console.error('Error parsing SSE data:', e);
              }
            }
          }
        }
      }

      const aiResponse = {
        type: 'assistant',
        content: fullResponse,
        timestamp: new Date().toISOString()
      };

      setMessages(prev => [...prev, aiResponse]);
      
      setTimeout(() => {
        setStreamingMessage('');
      }, 100);

    } catch (error) {
      console.error('Error getting answer:', error);
      toast({
        title: 'Error',
        description: 'Failed to get answer. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      position="fixed"
      top="0"
      right="0"
      width="400px"
      height="100vh"
      bg="white"
      shadow="lg"
      transform={isOpen ? "translateX(0)" : "translateX(100%)"}
      transition="all 0.2s ease-out"
      zIndex={1000}
      borderLeft="1px solid"
      borderColor="gray.200"
    >
      <Box position="relative" h="100%">
        <Flex 
          direction="column" 
          h="100%"
          borderTopLeftRadius="md"
          overflow="hidden"
        >
          <Flex 
            align="center" 
            gap={2} 
            p={4} 
            borderBottomWidth="1px" 
            bg="white"
          >
            <IconButton
              icon={<ChevronRightIcon />}
              aria-label="Close chat"
              variant="ghost"
              onClick={onClose}
              size="sm"
            />
            <ChatIcon />
            <Text fontWeight="bold">Ask DataDasher</Text>
          </Flex>

          <VStack flex={1} spacing={4} p={4} overflowY="auto">
            <Box flex={1} w="100%" overflowY="auto">
              {messages.map((message, index) => (
                <ChatMessage key={`${message.timestamp}-${index}`} message={message} />
              ))}
              {streamingMessage && (
                <ChatMessage
                  message={{
                    type: 'assistant',
                    content: streamingMessage,
                    timestamp: new Date().toISOString()
                  }}
                />
              )}
              {isLoading && !streamingMessage && (
                <Flex justify="flex-start" p={4}>
                  <Spinner size="sm" color="blue.500" />
                </Flex>
              )}
              <div ref={messagesEndRef} />
            </Box>
          </VStack>

          <Box p={4} borderTopWidth="1px" bg="white">
            <form onSubmit={handleSubmit}>
              <Flex>
                <Input
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  placeholder="Ask about the transcript..."
                  disabled={isLoading}
                  mr={2}
                  bg="white"
                  borderWidth="2px"
                  _focus={{
                    borderColor: "#0053BA",
                    boxShadow: 'none'
                  }}
                />
                <IconButton
                  type="submit"
                  icon={<ArrowForwardIcon />}
                  disabled={isLoading}
                  colorScheme="blue"
                  aria-label="Send message"
                />
              </Flex>
            </form>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

// Memoize the entire component
export default memo(TranscriptChatSidebar); 