import { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

export const useClientData = (id, serverUrl, toast) => {
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchClientData = async () => {
    try {
      const [clientResponse, insightsResponse] = await Promise.all([
        axios.get(`${serverUrl}/api/clients/${id}`),
        axios.get(`${serverUrl}/api/clients/${id}/insights`, {
          headers: {
            Authorization: `Bearer ${Cookies.get('jwtToken')}`,
          },
        })
      ]);

      setClient({
        ...clientResponse.data,
        insights: insightsResponse.data.insights
      });
      
      setLoading(false);
    } catch (error) {
      console.error('Error fetching client data:', error);
      toast({
        title: "Error fetching client data",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClientData();
  }, [id]);

  return { client, loading, fetchClientData };
}; 