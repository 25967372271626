import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const serverUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  useEffect(() => {
    // console.log('UserContext useEffect triggered');
    const token = Cookies.get('jwtToken');
    // console.log('JWT Token:', token ? 'Token exists' : 'No token found');

    if (!token) {
      console.log('No token found, skipping profile fetch');
      setLoading(false);
      return;
    }

    const fetchUserProfile = async () => {
      // console.log('Fetching user profile...', loading);
      try {
        // console.log('Making request to:', `${serverUrl}/api/user-profile`);
        const response = await axios.get(`${serverUrl}/api/user-profile`, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
          withCredentials: true
        });
        
        // console.log('User profile response:', response.data);
        
        if (response.data) {
          console.log('Setting user data:', response.data);
          setUser(response.data);

          // Check subscription status and redirect if not active
          if (!response.data.organization?.subscriptionDetails?.active) {
            // Don't redirect if already on checkout or certain auth pages
            const noRedirectPaths = ['/checkout', '/login', '/signup', '/forgot-password'];
            if (!noRedirectPaths.some(path => window.location.pathname.startsWith(path))) {
              window.location.href = '/checkout';
            }
          }
        } else {
          console.log('No user data in response');
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
        console.error('Error details:', {
          message: error.message,
          response: error.response?.data,
          status: error.response?.status
        });
      } finally {
        // console.log('Finished loading user profile');
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, [serverUrl, navigate]);

  // console.log('Current user context state:', { user, loading });
  
  return (
    <UserContext.Provider value={{ user, loading, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
}; 