import axios from 'axios';
import { SimpleGrid, Text, Flex, FormControl, FormLabel, Input, Button, useToast } from "@chakra-ui/react";
import  { Image } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import { loadStripe } from '@stripe/stripe-js';

const Signup = () => {
    const navigate = useNavigate();
    const toast = useToast();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [orgName, setOrgName] = useState('');
    const [error, setError] = useState('');
    const [disableButtons, setDisableButtons] = useState(false);
    const serverUrl = process.env.REACT_APP_API_URL;
    const [orgId, setOrgId] = useState('');
    const [organization, setOrganization] = useState(null);
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
    
    useEffect(() => {
        // Get orgId from URL parameters
        const params = new URLSearchParams(window.location.search);
        const orgIdParam = params.get('orgId');
        if (orgIdParam) {
            setOrgId(orgIdParam);
            // Fetch organization details
            fetch(`${serverUrl}/api/organization/${orgIdParam}`)
                .then(res => res.json())
                .then(data => {
                    if (data.organization) {
                        setOrganization(data.organization);
                        setOrgName(data.organization.name);
                    }
                })
                .catch(error => console.error('Error fetching organization:', error));
        }
    }, []);

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.post(`${serverUrl}/register`, { 
                firstName, 
                lastName, 
                email, 
                password, 
                orgName,
                orgId
            });
            
            Cookies.set('jwtToken', res.data.token);
            
            
            if (!orgId) {
                window.location.href = '/checkout';
            } else {
                window.location.href = '/';
            }
            
        } catch (error) {
            if (error.response && error.response.status === 400) {
                toast({
                    title: "Error",
                    description: error.response.data.msg || "An error occurred during registration",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                console.error(error);
            }
        }
    };

    const handleMicrosoftLogin = () => {
        window.location.href = `${serverUrl}/auth/microsoft`;
    };
    return (
        <SimpleGrid columns={2} spacing={0}  width={"100%"} height={"100%"} data-simple className="ma-0 pa-0">
            <Flex justifyContent={"center"} alignItems={"center"}
                flexDirection={"column"} width={"100%"} height={"100%"}
                >
                   <div className="h-100" style={{
                    maxWidth: "450px"
                   }}>
                        <div style={{color: '#2B3674', fontSize: 36, fontWeight: '700', wordWrap: 'break-word'}}>
                            {organization ? `Join ${organization.name}` : 'Sign Up'}
                        </div>
                        <div style={{color: '#A3AED0', fontSize: 16, fontWeight: '400', wordWrap: 'break-word'}}>Enter your name, email, and password to sign up.</div>
                        <FormControl className="mt-8" width={"410px"} isRequired={true}>
                            <FormLabel>
                                First Name
                            </FormLabel>
                            <Input
                            onChange={(e) => setFirstName(e.target.value)}
                            />
                        </FormControl>
                        <FormControl className="mt-8" width={"410px"} isRequired={true}>
                            <FormLabel>
                                Last Name
                            </FormLabel>
                            <Input
                            onChange={(e) => setLastName(e.target.value)}
                            />
                        </FormControl>
                        {!organization && (
                            <FormControl className="mt-8" width={"410px"} isRequired={true}>
                                <FormLabel>
                                    Organization Name
                                </FormLabel>
                                <Input
                                onChange={(e) => setOrgName(e.target.value)}
                                />
                            </FormControl>
                        )}
                        <FormControl className="mt-8" width={"410px"} isRequired={true}>
                            <FormLabel>
                                Email
                            </FormLabel>
                            <Input 
                            onChange={(e) => setEmail(e.target.value)}
                            />
                        </FormControl>
                        <FormControl
                        isRequired={true}
                        className="mt-4">
                            <FormLabel>
                                Password
                            </FormLabel>
                            <Input type="password"
                            onChange={(e) => setPassword(e.target.value)}
                            />
                        </FormControl>
                        <Button mt={4} width={"100%"} className="mt-10" background={"#00417D"} color={"white"} borderRadius={"16px"} disabled={disableButtons}
                        onClick={onSubmit}
                        >
                            Sign Up
                    </Button>
                    <Button width={"100%"} className="mt-10" background={"#00417D"} color={"white"} borderRadius={"16px"} disabled={disableButtons}
                        onClick={handleMicrosoftLogin}
                        mt={2}
                    >
                        Sign Up with Microsoft
                    </Button>
                    {error && <Text color="red.500" mt={4}>{error}</Text>}
                   </div>
                    <Button width={"100%"} className="mt-4" variant={'none'}
                        onClick={() => navigate('/login')}
                        >
                            Already have an account? Sign in here
                    </Button>
            </Flex>
            <Flex>
                <Image src="/images/login_bg.jpeg" alt="LOGO JPEG" width={"100%"} height={"100%"}/>

            </Flex>
        </SimpleGrid>
    )
};

export default Signup;