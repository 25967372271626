import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box, Heading, Table, Tbody, Td, Th, Thead, Tr,
  Input, InputGroup, InputLeftElement, Link, Skeleton, Text,
  HStack, Button, useToast, Icon, Tooltip
} from '@chakra-ui/react';
import Cookies from 'js-cookie';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { FaSearch, FaSync } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';

const Clients = () => {
  const toast = useToast();
  const [clients, setClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalPages, setTotalPages] = useState(1);
  const serverUrl = process.env.REACT_APP_API_URL;
  const wbUrl = 'https://app.crmworkspace.com/contacts/'
  const rtUrl = 'https://crm.redtailtechnology.com/contacts/'
  const [resyncLoading, setResyncLoading] = useState(true);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isSearching, setIsSearching] = useState(false);

  const handlePageChange = (value) => {
    setPage(value);
  };

  const openInCrm = async (id, crmSource) => {
    if (crmSource === 'Wealthbox') {
      window.open(`${wbUrl}${id}`, '_blank');
    }
    if (crmSource === 'RedTail') {
      window.open(`${rtUrl}${id}/`, '_blank');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsInitialLoading(true);
      try {
        const response = await axios.get(serverUrl + '/api/clients?page=' + page + '&pageSize=' + pageSize);
        setClients(response.data.clients);
        setSearchResults(response.data.clients);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching calls:", error);
        toast({
          title: "Error fetching calls.",
          description: `There was an error fetching calls from the server: ${error.response ? error.response.data.details : error.message}`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsInitialLoading(false);
      }
    };
    fetchData();
  }, [page, pageSize]);

  const handleChange = event => {
    setSearchTerm(event.target.value);
    setIsSearching(true);
  };

  useEffect(() => {
    const timer = setTimeout(async () => {
      setIsSearching(true);
      try {
        if (searchTerm) {
          // When searching, fetch all clients without pagination
          const response = await axios.get(`${serverUrl}/api/clients?search=${searchTerm}`);
          setSearchResults(response.data.clients);
        } else {
          // When not searching, revert to paginated results
          const response = await axios.get(`${serverUrl}/api/clients?page=${page}&pageSize=${pageSize}`);
          setSearchResults(response.data.clients);
        }
      } catch (error) {
        console.error("Error searching clients:", error);
      } finally {
        setIsSearching(false);
      }
    }, 300);

    return () => clearTimeout(timer);
  }, [searchTerm, serverUrl]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }

  const handleResync = async () => {
    setResyncLoading(true);
    console.log('handleResync - Initiating resync process');
    try {
      console.warn('handleResync - Resyncing all clients for the organization');
      const response = await axios.post(`${serverUrl}/api/clients/resync-all`, {}, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get('jwtToken')}`
        }
      });

      if (response.data.message) {
        toast({
          title: "Resync Initiated",
          description: "All client data resync has been initiated. This may take a few moments.",
          status: "info",
          duration: 5000,
          isClosable: true,
        });
        setResyncLoading(true);
        // wait 10 seconds before fetching jobs
        setTimeout(fetchJobs, 10000);
      } else {
        throw new Error(response.data.message || "Failed to initiate resync");
      }
    } catch (error) {
      console.error("Error initiating resync:", error);
      toast({
        title: "Resync Failed",
        description: `Failed to initiate resync: ${error.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      console.log('Clients Component: handleResync - Resync process completed');
    }
  };

  const fetchJobs = async (bypassToast = false) => {
    try {
      const token = Cookies.get('jwtToken');
      const response = await axios.get(`${serverUrl}/api/jobs?type=Resync&status=active`, { headers: { Authorization: `Bearer ${token}` }, withCredentials: true });
      console.log('Jobs:', response.data);
      setResyncLoading(response.data.length > 0);
      if (response.data.length > 0) {
        setTimeout(fetchJobs, 10000); // Poll every 10 seconds until the length is 0
      } else {
        setResyncLoading(false);
        if (!bypassToast) {
          toast({
            title: "Resync Completed",
            description: "All client data resync has been completed. Refresh the page to see the latest data.",
            status: "info",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    } catch (error) {
      console.error('Error fetching jobs:', error);
    }
  };

  useEffect(() => {
    fetchJobs(true);
  }, []);

  return (
    <Box bg="white" p={8} overflowY="auto">
      <HStack justify="space-between" mb={4}>
        <Heading color={'#00417D'} fontSize={'32px'}>Clients</Heading>
        <HStack>
          <Button
            onClick={handleResync}
            isLoading={resyncLoading}
            loadingText="Resyncing"
            size="sm"
            variant="outline"
            colorScheme="blue"
            leftIcon={
              <motion.div
                animate={{ rotate: resyncLoading ? 360 : 0 }}
                transition={{ duration: 1, repeat: resyncLoading ? Infinity : 0, ease: "linear" }}
              >
                <Icon as={FaSync} />
              </motion.div>
            }
          >
            Resync CRM
          </Button>
          <InputGroup w={'auto'}>
            <InputLeftElement pointerEvents="none">
              <Icon as={FaSearch} color={'#A1A1A1'} w={3} mb={'6px'} />
            </InputLeftElement>
            <Input
              placeholder="Search for a client"
              _placeholder={{
                color: '#A1A1A1',
                fontWeight: 700,
                textAlign: 'center'
              }}
              borderRadius={'6px'}
              size="sm"
              w='270px'
              value={searchTerm}
              onChange={handleChange}
            />
          </InputGroup>
        </HStack>
      </HStack>

      {isInitialLoading ? (
        <Box mt={12}>
          <Skeleton height="32px" width="200px" mb={4} />
          <Skeleton height="32px" width="200px" mb={4} />
          <Skeleton height="32px" width="200px" mb={4} />
        </Box>
      ) : (
        <Table variant="simple" mt={12}>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Last Correspondence</Th>
              <Th textAlign="right">Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {searchResults.map(client => (
              <Tr key={client.id}>
                <Td>
                  <Text>{client.firstName} {client.lastName}</Text>
                </Td>
                <Td>
                  {client.emails?.length > 0 ?
                    formatDate(client.emails[0].receivedAt) :
                    client.calls?.length > 0 ?
                      formatDate(client.calls[0].startTime) :
                      'No correspondence'}
                </Td>
                <Td textAlign="right">
                  <HStack justify="flex-end" spacing={2}>
                    <Button
                      as={RouterLink}
                      to={`/clients/${client.id}`}
                      colorScheme="blue"
                      size="sm"
                    >
                      View
                    </Button>
                    {(client.crmId && client.crmSource) && (
                      <Tooltip label={`Open in ${client.crmSource}`}>
                        <Button
                          leftIcon={<ExternalLinkIcon />}
                          onClick={() => openInCrm(client.crmId, client.crmSource)}
                          size="sm"
                          variant="outline"
                          colorScheme="blue"
                        >
                          {client.crmSource}
                        </Button>
                      </Tooltip>
                    )}
                  </HStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}

      <HStack justify="flex-end" mt={4}>
        {page > 1 && <Button onClick={() => handlePageChange(page - 1)}>Previous</Button>}
        {page < totalPages && <Button onClick={() => handlePageChange(page + 1)}>Next</Button>}
        <Text>Page {page} of {totalPages}</Text>
      </HStack>
    </Box>
  );
};

export default Clients;